import { FC } from 'react';
import LoggedInUserDropdown from './LoggedInUserDropdown';
import { Heading, HeadingSize } from '../../shared/text/Heading';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../../recoil/atoms/Clients';
import { inConsultantSpaceAtom, topNavHeadingAtom } from '../../../recoil/atoms/Workspace';
import NotificationDropdown from '../../notifications/NotificationDropdown';
import HelpTrigger from './HelpTrigger';
import LanguageDropdown from './LanguageDropdown';
import CurrentUserService from '../../../services/CurrentUserService';
import { currentUserAtom } from '../../../recoil/atoms/Auth';
import StorageService from '../../../services/StorageService';

export const TopNav: FC = () => {
  const client = useRecoilValue(currentClientAtom);
  const inConsultantDashboard = useRecoilValue(inConsultantSpaceAtom);
  const topNavHeading = useRecoilValue(topNavHeadingAtom);
  const [currentUser, setCurrentUser] = useRecoilState(currentUserAtom);

  const topHeading = topNavHeading || (!inConsultantDashboard && client?.name);

  const updateUserPreferredLanguage = (languageCode: string) => {
    if (currentUser) {
      CurrentUserService.updateProfile({
        emailAddress: currentUser.email || '',
        firstName: currentUser?.firstName || '',
        lastName: currentUser?.lastName || '',
        jobTitle: currentUser?.jobTitle || '',
        languageCode: languageCode,
        phoneNumber: currentUser?.phoneNumber || '',
      }).then((res) => {
        StorageService.setLang(res.data.language);
        setCurrentUser((prev) => prev && { ...prev, language: res.data.language });
      });
    }
  };

  return (
    <div className="border-gray-5 flex min-h-28 flex-col justify-between border-b-2 bg-white pt-4">
      <div className="flex w-full items-center justify-between">
        <div className={`flex ${topHeading ? 'flex-col justify-between' : 'items-center'} h-full`}>
          <div className="relative flex items-center gap-2 pl-6">
            {topHeading && (
              <Heading size={HeadingSize.H1} actualSize={HeadingSize.H5}>
                {topHeading}
              </Heading>
            )}{' '}
            <div id="portal-top-nav-tenant"></div>
          </div>
        </div>
        <div className="piped-items flex items-center justify-end ">
          <div className="mr-5 pt-1">
            <div className="flex items-center gap-3">
              <NotificationDropdown />
              <HelpTrigger />
            </div>
          </div>
          <div className="mr-6 pt-1">
            <LanguageDropdown onChange={updateUserPreferredLanguage} />
          </div>
          <LoggedInUserDropdown />
        </div>
      </div>
      <div id="portal-top-nav" className="p-2 pl-6"></div>
    </div>
  );
};

export default TopNav;
