import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationBellIcon from '../shared/icon/NotificationBellIcon';
import { useLatestNotificationsProvider } from '../../contexts/LatestNotificationsContext';
import NotificationDropdownSection from './NotificationDropdownSection';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';

const NotificationDropdown: FC = () => {
  const location = useLocation();
  const onNotifications = (location?.pathname || '').includes('/notification');
  const { t } = useTranslation('common');

  const [show, setShow] = useState(false);
  const bellRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const {
    latestUnreadNotifs,
    totalCount: totalUnreadNotifs,
    markAsRead,
    markAllAsRead,
    isLoadingNotifs,
    showNotifications,
  } = useLatestNotificationsProvider();

  const BellIcon = useMemo(
    () =>
      function BellIcon() {
        return (
          <div
            tabIndex={0}
            className="relative"
            style={{ cursor: onNotifications ? 'default' : 'pointer' }}
            {...mouseAndKeyboardCallbackProps(() => {
              if (!onNotifications) setShow(!show);
            })}
          >
            {totalUnreadNotifs > 0 && (
              <span className="text-dpm-12 bg-semantic-alert absolute -right-[3px] -top-[3px] flex h-3 w-3 items-center justify-center rounded-full text-white" />
            )}
            <div ref={bellRef}>
              <NotificationBellIcon className="h-7 w-7 bg-black p-1 text-white" />
            </div>
          </div>
        );
      },
    [onNotifications, show, totalUnreadNotifs],
  );

  const NotificationLink = useMemo(
    () =>
      function NotificaionLink() {
        return (
          <div className="w-full pt-6 text-right">
            <Link onClick={() => setShow(false)} className="text-color-1 text-dpm-14 cursor-pointer font-medium underline" to={'/notifications'}>
              {t('notifications.view-all')}
            </Link>
          </div>
        );
      },
    [t],
  );

  const position = {
    top: bellRef.current?.getBoundingClientRect().bottom,
    right: window.innerWidth - 50 - (bellRef.current?.getBoundingClientRect().left || 0),
  };

  useEffect(() => {
    const mouseDownListener = (e: MouseEvent): void => {
      if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', mouseDownListener);

    return () => {
      document.removeEventListener('mousedown', mouseDownListener);
    };
  }, []);

  if (!showNotifications) {
    return null;
  }

  return (
    <div className="relative">
      <BellIcon />
      {show && (
        <div
          ref={popupRef}
          style={{ ...position, minWidth: '30rem' }}
          className="max-h-5/6 fixed right-0 top-0 z-50 max-w-sm overflow-auto border border-gray-100 bg-white px-2 pt-0 shadow-lg"
        >
          <div className="sticky top-0 z-50 flex items-center justify-between bg-white pb-6 pt-6">
            <div className="text-dpm-20 font-medium">{t('notifications.heading')}</div>
            {latestUnreadNotifs.length > 0 && (
              <button className="text-color-1 text-dpm-14 cursor-pointer font-medium underline" onClick={markAllAsRead}>
                {t('notifications.mark-all-as-read')}
              </button>
            )}
          </div>
          <NotificationDropdownSection
            data={latestUnreadNotifs}
            markAsRead={markAsRead}
            isLoading={isLoadingNotifs}
            onItemClick={() => setShow(false)}
          />
          <div className="sticky bottom-0 z-50 flex justify-between bg-white pb-6 ">
            <NotificationLink />
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationDropdown;
